import axios from "@/lib/axios";
import Vue from "vue";

/**
 * interface LastLocationUpdate {
 *      name: string,
 *      time: string
 * }
 *
 * interface Location {
 *      id: int
 *      numbers: int[],
 *      name: string,
 *      pushEnabled: bool,
 *      lastUpdate: LastLocationUpdate
 * }
 *
 * interface TextContent {
 *      status: string
 * }
 */

const state = {
    // Location[]
    locations: [],

    // TextContent
    content: {
        explain_intro: null,
        explain_state1: null,
        explain_state2: null,
        explain_state3: null,
        status: null,
    },
};

const mutations = {
    /** Sets all locations in one go */
    setLocations(state, locations) {
        if (locations) {
            state.locations = locations;
        } else {
            state.locations = [];
        }
    },

    /** Finds location based on id and updates it */
    updateLocation(state, location) {
        const locationIdx = state.locations.findIndex((l) => l.id === location.id);
        if (locationIdx !== -1) {
            Vue.set(state.locations, locationIdx, location);
        }
    },

    /** Updates the text-content */
    setContent(state, content) {
        state.content = content;
    },
};

const actions = {
    async fetchLocations(context) {
        try {
            let res = await axios.get("/doorman/user_locations");
            if (res.status === 200) {
                context.commit("setLocations", res.data);
                return;
            }

            throw res;
        } catch (err) {
            // TODO: Better handling of error in retrieving locations
            context.commit("setLocations", null);
        }
    },

    async updateLocation(context, { pollingLocationId, queueLength }) {
        try {
            let res = await axios.post(`/doorman/updatequeue/${pollingLocationId}/${queueLength}`);
            if (res.status === 200) {
                context.commit("updateLocation", res.data);
                return;
            }

            throw res;
        } finally {
            /* */
        }
    },

    async setPushEnabledLocation(context, { pollingLocationId, enabled }) {
        try {
            let res = await axios.post(`/doorman/pushEnabled/${pollingLocationId}/${enabled ? 1 : 0}`);
            if (res.status === 200) {
                context.commit("updateLocation", res.data);
                return;
            }
        } finally {
            /* */
        }
    },

    async fetchTextContent(context) {
        try {
            let res = await axios.get("/doorman/textContent");
            if (res.status === 200) {
                context.commit("setContent", res.data);
                return;
            }

            throw res;
        } catch (err) {
            // TODO: Better handling of error in retrieving text content
            context.commit("setContent", {});
        }
    },
};

export default {
    state,
    mutations,
    actions,
};
