import Vue from "vue";
import VueRouter from "vue-router";

const Home = () => import("../views/Home.vue");
const NeedLogin = () => import("../views/NeedLogin.vue");
const LoggedOut = () => import("../views/LoggedOut.vue");

import store from "@/store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/needLogin",
        name: "NeedLogin",
        component: NeedLogin,
    },
    {
        path: "/loggedOut",
        name: "LoggedOut",
        component: LoggedOut,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    // If we have no authentication information, redirect to a view saying we have no user
    if (store.state.auth.user.name === null && to.name !== "NeedLogin" && to.name !== "LoggedOut") {
        next({ name: "NeedLogin" });

        return; // make sure not to call next twice
    }

    // continue like normal
    next();
});

export default router;
