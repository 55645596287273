import Vue from "vue";
import Vuetify from "vuetify/lib";
import nl from "vuetify/es5/locale/nl";

Vue.use(Vuetify);

const appElement = document.getElementById("app");
const municipality = appElement.getAttribute("data-municipality");

import themes from "@/config/themes.js";

export default new Vuetify({
    theme: {
        themes: themes[municipality],
    },
    lang: {
        locales: { nl },
        current: "nl",
    },
    icons: {
        iconfont: "fa",
    },
});
