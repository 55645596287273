import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.js";
import locations from "./locations.js";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        locations,
    },
});
