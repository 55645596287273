<template>
    <v-app>
        <v-app-bar app :color="headerColor" :dark="headerDark" prominent shrink-on-scroll :src="headerImage">
            <template v-slot:img="{ props }">
                <v-img v-bind="props" cover position="left bottom" />
            </template>
            <v-app-bar-nav-icon>
                <v-img :src="headerLogo" max-height="48" max-width="64" contain :alt="headerAlt" />
            </v-app-bar-nav-icon>
            <v-toolbar-title v-if="hasUser">welkom {{ userName }}</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn v-if="hasUser" text large @click.prevent="logout" @touchend.prevent="logout">
                Uitloggen
                <v-icon right :loading="loggingOut">fas fa-sign-out-alt</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main>
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
import themes from "@/config/themes.js";

export default {
    name: "App",

    components: {},

    data: () => ({
        loggingOut: false,
    }),

    computed: {
        headerLogo() {
            return themes[this.$municipality].image;
        },

        headerImage() {
            return themes[this.$municipality].header;
        },

        headerDark() {
            return themes[this.$municipality].headerDark;
        },

        headerColor() {
            return themes[this.$municipality].headerColor;
        },

        headerAlt() {
            return themes[this.$municipality].headerAlt;
        },

        hasUser() {
            return this.$store.state.auth.user.name !== null;
        },

        userName() {
            return this.$store.state.auth.user.name;
        },
    },

    methods: {
        async logout() {
            this.loggingOut = true;

            try {
                await this.$store.dispatch("logout");
                this.$router.replace({ name: "LoggedOut" });
            } finally {
                this.loggingOut = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.v-app-bar.v-app-bar--shrink-on-scroll .v-toolbar__title {
    font-size: 1.25rem;
}
</style>
