import axios from "@/lib/axios";

const state = {
    user: {
        name: null,
    },

    // if push is supported _and_ approved by the user
    pushAvailable: false,

    // if push-messages are supported at all
    pushSupported: null,

    // the last message we received through _foreground_-push-messaging
    pushMessage: {
        title: null,
        body: null,
    },
};

const mutations = {
    setUser(state, user) {
        if (user) {
            state.user = user;
        } else {
            state.user = {
                name: null,
            };
        }
    },

    setPushAvailable(state, available) {
        state.pushAvailable = available;
    },

    setPushSupported(state, supported) {
        state.pushSupported = supported;
    },

    setPushMessage(state, message) {
        state.pushMessage = message;
    },
};

const actions = {
    async retrieveUserInfo(context) {
        try {
            let res = await axios.get("/doorman/get_user");
            if (res.status === 200) {
                context.commit("setUser", {
                    name: res.data.name,
                });
                return;
            }

            throw res;
        } catch (err) {
            context.commit("setUser", null);
        }
    },

    async logout(context) {
        try {
            let res = await axios.get("/doorman/logout");
            if (res.status === 200) {
                context.commit("setUser", null);
                return;
            }

            throw res;
        } finally {
            /* TODO: handle error while logging out*/
        }
    },
};

export default {
    state,
    mutations,
    actions,
};
