/* global firebase */
/* eslint-disable no-console */

import firebaseConfig from "@/config/firebaseConfig.js";
import store from "@/store";
import axios from "@/lib/axios";

firebase.initializeApp(firebaseConfig);

export function fcmInit(registration) {
    const messaging = firebase.messaging();
    messaging.onMessage((payload) => {
        // this is triggerd when a push message is received and we are in the foreground
        store.commit("setPushMessage", payload.notification);
    });

    // Record that we _do_ have push support.
    messaging
        .getToken({
            serviceWorkerRegistration: registration,
            vapidKey: "BNHusUAywFMt0Tqayhw-2vumSYL8055gJSGruu4_nv1hQEd7DBh9ONcif8nxx8Gpl6P_C6lgx1hBeaNX8xqM6mo",
        })
        .then((currentToken) => {
            // push available
            // this already asked for permission

            axios.post("/doorman/set-push-token", {
                token: currentToken,
            });

            store.commit("setPushAvailable", true);
        })
        .catch((err) => {
            // push not available (declined / not supported / ...)
            console.error("FCM getToken failed: ", err);
            axios.post("/doorman/set-push-token", {
                token: null,
            });
            store.commit("setPushAvailable", false);
        });
}

if (!firebase.messaging.isSupported()) {
    // Record that we have no push supported in our inapp-state.
    store.commit("setPushSupported", false);
} else {
    store.commit("setPushSupported", true);
}
