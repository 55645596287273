// This module is nice, but we have to initialize Firebase in the Service-worker (sw.js) as well.
// Since we can't use imports there, there is a copy & paste of this config in sw.js.
// If you modify this, don't forget to modify sw.js as well!

export default {
    apiKey: "AIzaSyCzxTgWMiR1RKqfeid3UHTmi22pjzUuHys",
    authDomain: "wachtrij-ccf82.firebaseapp.com",
    databaseURL: "https://wachtrij-ccf82.firebaseio.com",
    projectId: "wachtrij-ccf82",
    storageBucket: "wachtrij-ccf82.appspot.com",
    messagingSenderId: "872187939248",
    appId: "1:872187939248:web:e3981048910dac5fab6e75",
};
