import denhaagLogo from "@/assets/denhaag/logo.svg";
import denhaagHeader from "@/assets/denhaag/header.svg";

import groningenLogo from "@/assets/groningen/logo.svg";
import groningenHeader from "@/assets/groningen/header.svg";

import rotterdamLogo from "@/assets/rotterdam/logo.svg";

import zoetermeerLogo from "@/assets/zoetermeer/logo.svg";

import utrechtLogo from "@/assets/utrecht/logo.svg";
import utrechtHeader from "@/assets/utrecht/header.svg";

import amsterdamLogo from "@/assets/amsterdam/logo.svg";

import almereLogo from "@/assets/almere/logo.svg";

export default {
    denhaag: {
        light: {
            primary: "#227b3c",
            secondary: "#edbf07",
            accent: "#82b1ff",
            error: "#ff5252",
            info: "#2196f3",
            success: "#4caf50",
            warning: "#ffc107",
        },
        image: denhaagLogo,
        header: denhaagHeader,
        headerDark: false,
        headerColor: "#ffffff",
        headerAlt: "Logo Den Haag",
    },
    rotterdam: {
        light: {
            primary: "#168838",
            secondary: "#242424",
            accent: "#82b1ff",
            error: "#ff5252",
            info: "#2196f3",
            success: "#4caf50",
            warning: "#ffc107",
        },
        image: rotterdamLogo,
        header: null,
        headerDark: false,
        headerColor: "#ffffff",
        headerAlt: "Logo Rotterdam",
    },
    zoetermeer: {
        light: {
            primary: "#0072b9",
            secondary: "#ffe00f",
            accent: "#82b1ff",
            error: "#ff5252",
            info: "#2196f3",
            success: "#4caf50",
            warning: "#ffc107",
        },
        image: zoetermeerLogo,
        header: null,
        headerDark: true,
        headerColor: "#3893ce",
        headerAlt: "Logo Zoetermeer",
    },
    groningen: {
        light: {
            primary: "#e60004",
            secondary: "#000000",
            accent: "#82b1ff",
            error: "#ff5252",
            info: "#2196f3",
            success: "#4caf50",
            warning: "#ffc107",
        },
        image: groningenLogo,
        header: groningenHeader,
        headerDark: false,
        headerColor: "#f5f5f5",
        headerAlt: "Logo Groningen",
    },
    utrecht: {
        light: {
            primary: "#cc0000",
            secondary: "#2a5587",
            accent: "#82b1ff",
            error: "#ff5252",
            info: "#2196f3",
            success: "#4caf50",
            warning: "#ffc107",
        },
        image: utrechtLogo,
        header: utrechtHeader,
        headerDark: false,
        headerColor: "#ffffff",
        headerAlt: "Logo Utrecht",
    },
    amsterdam: {
        light: {
            primary: "#e81521",
            secondary: "#000000",
            accent: "#82b1ff",
            error: "#ff5252",
            info: "#2196f3",
            success: "#4caf50",
            warning: "#ffc107",
        },
        image: amsterdamLogo,
        header: null,
        headerDark: false,
        headerColor: "#f5f5f5",
        headerAlt: "Logo Amsterdam",
    },
    almere: {
        light: {
            primary: "#34a6a5",
            secondary: "#000000",
            accent: "#82b1ff",
            error: "#b81839",
            info: "#2196f3",
            success: "#4caf50",
            warning: "#ffc107",
        },
        image: almereLogo,
        header: null,
        headerDark: false,
        headerColor: "#f5f5f5",
        headerAlt: "Logo Almere",
    },
};
