import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.config.productionTip = false;

const appElement = document.getElementById("app");
const municipality = appElement.getAttribute("data-municipality");

Vue.prototype.$municipality = municipality;

// First retrieve user-information before even trying something with Vue
// that way the user-object is initialized (or not) before Vue's first visibility

store.dispatch("retrieveUserInfo").then(() => {
    new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App),
    }).$mount("#app");
});
