import axios from "axios";
import store from "@/store";
import router from "@/router";

// configure the global instance or add handlers.
// it's then accessible throughout the app
const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE,
    withCredentials: true,
});

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
                store.commit("setUser", null);
                if (router.currentRoute && router.currentRoute.name) {
                    if (router.currentRoute.name !== "NeedLogin") {
                        router.push({ name: "NeedLogin" });
                    }
                }
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
